import axios from "axios";

export default {
    async getTimbrature(userID: string) {
        const data = new FormData();
        data.append("limit", "50");
        data.append("offset", "0");
        data.append("where[presenze_dipendente]", userID.toString());
        data.append("orderby", "presenze_data_inizio");
        data.append("orderdir", "desc");

        const response = await axios.post(`rest/v1/search/presenze`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        localStorage.setItem("presenze", JSON.stringify(response.data.data));
        //console.log(response);
        return response.data.data;
    },

    async timbraEntrata(dipendenteId, entrata, reparto, scope) {
        const data = new FormData();
        data.append("dipendente_id", dipendenteId);
        data.append("ora_entrata", entrata);
        data.append("reparto_id", reparto);
        data.append("scope", scope);

        //const response = await axios.post(`http://192.168.11.124/ingegno_plan/modulo-hr/app/timbraEntrata`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraEntrata`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async chiudiPresenza(dipendenteId, uscita, scope) {
        const data = new FormData();

        data.append("dipendente_id", dipendenteId);
        data.append("ora_uscita", uscita);
        data.append("scope", scope);

        //const response = await axios.post(`http://192.168.11.124/ingegno_plan/modulo-hr/app/timbraUscita`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraUscita`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * Save straordinario
     */
    async editPresenza(id, ore, note) {
        console.log(ore, note);
        const data = new FormData();
        data.append("presenze_straordinario", ore);
        data.append("presenze_note", note);

        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}rest/v1/edit/presenze/${id}`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * Scan QR code
     */
    async scanQrCode(qrValue, dipendente_id, cliente, commessa) {
        const data = new FormData();
        data.append("dipendente_id", dipendente_id);
        data.append("value", qrValue);
        data.append("cliente", cliente);
        data.append("commessa", commessa);

        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/qrcode/inquadraQr`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * Crea presenza completa
     */
    async creaPresenza(data: Record<string, any>) {
        const response = await axios.post(`rest/v1/create/presenze`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * Cancella presenza
     */
    async deletePresenza(presenza_id: string, user_id: string) {
        const data = new FormData();
        data.append("cancellazione_da_app", "1");
        data.append("user_id", user_id);
        const response = await axios.post(`rest/v1/delete/presenze/${presenza_id}`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        console.log(response);
        return response;
    },

    async timbraNFC(dipendente, reparto, cliente, commessa) {
        const data = new FormData();

        data.append("dipendente_id", dipendente);
        data.append("reparto_id", reparto);
        data.append("cliente", cliente);
        data.append("commessa", commessa);

        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/timbraNfc`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
};
